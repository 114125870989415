import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';
import { HomePage } from './home/home.page';
import { RoutingGuard } from './routing.guard';
import { ProfilePage } from './auth/profile/profile.page';
import { TutorialPage } from './pages/tutorial/tutorial.page';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToProfile = () => redirectLoggedInTo(['profile']);


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    component: HomePage,
    canActivate: [RoutingGuard],
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule),
    ...canActivate(redirectLoggedInToProfile)
  },
  {
    path: 'resetpw',
    loadChildren: () => import('./auth/resetpw/resetpw.module').then( m => m.ResetpwPageModule)
  },
  {
    path: 'profile',
    // component: ProfilePage,
    loadChildren: () => import('./auth/profile/profile.module').then( m => m.ProfilePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'signup',
    loadChildren: () => import('./auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'information',
    loadChildren: () => import('./pages/information/information.module').then( m => m.InformationPageModule)
  },
  {
    path: 'overview',
    loadChildren: () => import('./pages/overview/overview.module').then( m => m.OverviewPageModule)
  },
  {
    path: 'my-classifications',
    loadChildren: () => import('./pages/my-classifications/my-classifications.module').then( m => m.MyClassificationsPageModule)
  },
  {
    path: 'ranking',
    loadChildren: () => import('./pages/ranking/ranking.module').then( m => m.RankingPageModule)
  },
  {
    path: 'results',
    loadChildren: () => import('./pages/results/results.module').then( m => m.ResultsPageModule)
  },
  {
    path: 'classify',
    loadChildren: () => import('./pages/classify/classify.module').then( m => m.ClassifyPageModule)
  },
  {
    path: 'options',
    loadChildren: () => import('./pages/options/options.module').then( m => m.OptionsPageModule)
  },
  {
    path: 'live-status',
    loadChildren: () => import('./pages/live-status/live-status.module').then( m => m.LiveStatusPageModule)
  },
  {
    path: 'transient-list',
    loadChildren: () => import('./backend/transient-list/transient-list.module').then( m => m.TransientListPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },  {
    path: 'test',
    loadChildren: () => import('./backend/test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'superuser',
    loadChildren: () => import('./pages/superuser/superuser.module').then( m => m.SuperuserPageModule)
  },
  {
    path: 'followups',
    loadChildren: () => import('./pages/followups/followups.module').then( m => m.FollowupsPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
