import { Component, ViewChild } from '@angular/core';
import SwiperCore, { Keyboard, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { IonicSlides } from '@ionic/angular';

SwiperCore.use([Keyboard, Navigation, Pagination, IonicSlides]);

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  @ViewChild('swiperhome', { static: false }) swiper: SwiperComponent;
  config: SwiperOptions = {
    keyboard: true,
    //navigation: true,
    pagination: true,
  };

  constructor() {}

  ionViewWillEnter() {
    this.swiper.swiperRef.slideTo(0, 0);
  }

}
