<ion-header class="ion-no-border">
  <ion-toolbar mode="ios" color="tertiary">
    <ion-title >Black Hole Finder</ion-title>
    <ion-buttons slot="end">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <swiper #swiperhome [config]="config">

    <ng-template swiperSlide>

      <ion-card class="firstpage">
        <ion-img src="../../assets/logos/Black_Hole_Finder_vertical_Color_white_RGB.png"></ion-img>
        <ion-card-header>
          <ion-card-title>
        {{ "homePage.page1.ion-card-title" | translate }}
              </ion-card-title>
              <ion-card-subtitle mode="md">{{ "homePage.page1.ion-card-subtitle" | translate }}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
        <div class="ion-text-left div-content">
                    {{ "homePage.page1.ion-card-content" | translate }}
        </div>
      </ion-card-content>
          </ion-card>
    </ng-template>

    <ng-template swiperSlide>
      <ion-card>

        <ion-card-header>
          <img src="../../assets/images/telescopes.png"/>
        <ion-card-title>{{ "homePage.page2.ion-card-title" | translate }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
      <div class="ion-text-left div-content">
                  {{ "homePage.page2.ion-card-content" | translate }}
        </div>
        </ion-card-content>
      </ion-card>
    </ng-template>
    <ng-template swiperSlide>
      <ion-card><ion-card-header>
        <img src="../../assets/images/LaSilla_overview_new.jpg"/>
        <ion-card-title>
          {{ "homePage.page3.ion-card-title" | translate }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
      <div class="ion-text-left div-content">
                  {{ "homePage.page3.ion-card-content" | translate }}
        </div>
        </ion-card-content>
      </ion-card>
    </ng-template>
    <ng-template swiperSlide>
      <ion-card>
        <ion-card-header>
          <img src="../../assets/images/LaSilla_2BG_small.jpg"/>
        <ion-card-title>
          {{ "homePage.page4.ion-card-title" | translate }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
      <div class="ion-text-left div-content">
          {{ "homePage.page4.ion-card-content" | translate }}
      </div>
      </ion-card-content>
      <ion-card-content>
      <ion-button routerLink="/tutorial" expand="block">{{ "homePage.page4.button-title" | translate }}</ion-button>
      </ion-card-content>
      </ion-card>
    </ng-template>
  </swiper>

</ion-content>
