/* eslint-disable @typescript-eslint/dot-notation */
import { StatsService } from './services/stats.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Auth, User, user } from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  AlertController,
  MenuController,
  NavController,
  Platform,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { Storage } from '@ionic/storage-angular';

import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { UserType } from './services/usertype';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  user = null;
  language = '';
  superUser = false;

  userSubscription: Subscription;

  constructor(
    private menuCtrl: MenuController,
    private authService: AuthService,
    private router: Router,
    private afAuth: Auth,
    private storage: Storage,
    private statsService: StatsService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private platform: Platform,
    private navController: NavController
  ) {

  }
  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
  async ngOnInit() {
    await this.storage.create();

    this.userSubscription = user(this.afAuth).subscribe(async (aUser: User | null) => {
      this.user = aUser;

      // Check if user is null before proceeding
      if (aUser !== null) {
        await timer(1000).toPromise();
        this.statsService.getFirestoreUserType(this.user.uid).then(async (type) => {
          // console.log('User type: ', type);
          if (type === 'Demoted') {
            return;
          }
          else if (type === 'User') {
            // const count = await this.statsService.getUserClassificationCount(this.user.uid);
            // // console.log('User classification count: ', count);
            // if (count > 5000) {
            //   await this.statsService.setFirestoreUserType(
            //     this.user.uid,
            //     UserType.SuperUser
            //   );
            //   // console.log('User type set to SuperUser');

            //   // Fetch translations asynchronously
            //   const translations = await this.translateService.get([
            //     'congratulations',
            //     'you-are-superuser'
            //   ]).toPromise();

            //   // Create and present the alert
            //   const alert = await this.alertController.create({
            //     header: translations['congratulations'],
            //     message: translations['you-are-superuser'],
            //     buttons: [
            //       {
            //         text: 'OK',
            //         handler: () => {
            //           // Navigate to the SuperUser page when OK is clicked
            //           this.navController.navigateRoot('/superuser');
            //         },
            //       },
            //     ],
            //   });
            //   await alert.present();
            // }
          } else if (type === 'SuperUser' || type === 'Expert' || type === 'Admin') {
            this.superUser = true;
          }
        }).catch(error => {
          console.error('Error fetching user type:', error);
        });
      } else {
        console.log('User is null, skipping user-specific logic.');
      }
    });

    this.initializePush();

    // Language setup
    this.translateService.setDefaultLang('en'); // Set default language to English

    this.language = await this.storage.get('language');
    if (this.language === null) {
      console.log(
        'no value for language in storage, trying getting from browser'
      );
      this.language = this.translateService.getBrowserLang();
    }
    this.translateService.use(this.language);
    await this.storage.set('language', this.language);
  }

  async initializePush() {
    // Initialize push notifications
    if (this.platform.is('capacitor')) {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          console.log('Push notifications permissions granted');
          PushNotifications.register();
        } else {
          console.log('Push notifications permissions denied');
        }
      });
    }

    // Check subscription and set to true if not set
    let transientNotification = await this.storage.get('transientsNotification');
    if (transientNotification === null) {
      transientNotification = true;
      if (this.platform.is('capacitor')) {
      FCM.subscribeTo({ topic: 'transients' });
      }
      await this.storage.set('transientsNotification', transientNotification);
    }

    let newsNotification = await this.storage.get('newsNotification');
    if (newsNotification === null) {
      newsNotification = true;
      if (this.platform.is('capacitor')) {
        FCM.subscribeTo({ topic: 'news' });
      }
      await this.storage.set('newsNotification', newsNotification);
    }
  }

  closeMenu() {
    this.menuCtrl.close();
  }

  async logout() {
    await this.authService.logout();
    this.superUser = false;
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }
}

