<ion-app>
  <ion-menu side="end" contentId="main" swipeGesture="false" color="tertiary" mode="md">
    <ion-menu-toggle>
    <ion-header>
      <ion-toolbar>
        <ion-item color="dark" lines="none" routerLink="home">
          <ion-icon name="home-outline"></ion-icon>
          <ion-label>BLACKHOLEFINDER</ion-label>
        </ion-item>
      </ion-toolbar>
    </ion-header>
  </ion-menu-toggle>
    <ion-content>
      <ion-list class="ion-no-padding" lines="full">
        <ion-list-header>
          <ion-label>{{ "menu.account" | translate }}</ion-label>
        </ion-list-header>
        <ion-menu-toggle>
          <ion-item button routerLink="signup" [disabled]="user">
            <ion-icon name="person-add-outline"></ion-icon>

            <ion-label>{{ "menu.signup" | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item button routerLink="login" [disabled]="user">
            <ion-icon name="log-in-outline"></ion-icon>
            <ion-label>{{ "menu.signin" | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
        <ion-item button routerLink="profile" [disabled]="!user">
          <ion-icon name="person-circle-outline"></ion-icon>
          <ion-label>{{ "menu.profile" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item button (click)="logout()" [disabled]="!user || user.isAnonymous === true">
          <ion-icon name="log-out-outline"></ion-icon>
          <ion-label>{{ "menu.signout" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-list-header>
        <ion-label>{{ "menu.contribute" | translate }}</ion-label>
      </ion-list-header>
      <ion-menu-toggle>
        <ion-item button routerLink="tutorial">
          <ion-icon name="bulb-outline"></ion-icon>
          <ion-label>{{ "menu.tutorial" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item button routerLink="classify" [disabled]="!user">
          <ion-icon name="grid-outline"></ion-icon>
          <ion-label>{{ "menu.classify" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item button routerLink="my-classifications" [disabled]="!user || user.isAnonymous === true">
          <ion-icon name="file-tray-stacked-outline"></ion-icon>
          <ion-label>{{ "menu.my-classifications" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item button routerLink="ranking"  [disabled]="!user || user.isAnonymous === true">
          <ion-icon name="medal-outline"></ion-icon>
          <ion-label>{{ "menu.ranking" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-list-header *ngIf="superUser">
        <ion-label>{{ "menu.superUser" | translate }}</ion-label>
      </ion-list-header>
      <ion-menu-toggle *ngIf="superUser">
        <ion-item button routerLink="superuser">
          <ion-icon name="star-outline"></ion-icon>
          <ion-label>{{ "menu.instructions" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="superUser">
        <ion-item button routerLink="followups">
          <ion-icon name="telescope-outline"></ion-icon>
          <ion-label>{{ "menu.followUps" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-list-header>
        <ion-label>{{ "menu.project" | translate }}</ion-label>
      </ion-list-header>
      <ion-menu-toggle>
        <ion-item button routerLink="news">
          <ion-icon name="newspaper-outline"></ion-icon>
          <ion-label>{{ "menu.news" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item button routerLink="information">
          <ion-icon name="information-circle-outline"></ion-icon>
          <ion-label>{{ "menu.information" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item button routerLink="live-status">
          <ion-icon name="pulse-outline"></ion-icon>
          <ion-label>{{ "menu.live-status" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item button routerLink="overview">
          <ion-icon name="bar-chart-outline"></ion-icon>
          <ion-label>{{ "menu.overview" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item button routerLink="terms">
          <ion-icon name="book-outline"></ion-icon>
          <ion-label>{{ "menu.terms" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-list-header>
        <ion-label>{{ "menu.settings" | translate }}</ion-label>
      </ion-list-header>
      <ion-menu-toggle>
        <ion-item button routerLink="options">
          <ion-icon name="settings-outline"></ion-icon>
          <ion-label>{{ "menu.options" | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
