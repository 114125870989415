import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class RoutingGuard implements CanActivate {
  startupPage = 'home';
  constructor(private router: Router, private storage: Storage){
  }

  async canActivate(): Promise<boolean> {
    this.startupPage = await this.storage.get('startupPage');
    if (this.startupPage === null) {
      this.startupPage = 'home';
      this.storage.set('startupPage', this.startupPage);
    } else {
      this.router.navigateByUrl(this.startupPage, { replaceUrl: true });
    }
    return true;
  }
}
