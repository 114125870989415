/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  DocumentData,
  deleteDoc,
  where,
  serverTimestamp,
  docData,
  getCountFromServer,
  increment,
} from '@angular/fire/firestore';
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
} from '@angular/fire/firestore';
import { query, limit, orderBy } from '@angular/fire/firestore';
import { Classifier } from './dbhc.model';
import { UserType } from './usertype';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { AnswerService } from './answer.service';

@Injectable({
  providedIn: 'root',
})


export class StatsService {
  topClassifiers: any[] = [];
  isUpdatingRanking = false;

  constructor(
    private afs: Firestore,
    private answerService: AnswerService
  ) {}

  //THIS SERVICE WRITES AND READS DATA TO **FIRESTORE** AND DOES NOT AFFECT USERS IN THE AUTHENTICATION FIREBASE MODULE
  //the purpose of the service is to store some user related data in the FIRESTORE database for easy fetching/caching
  //also because the firebase auth module does not allow user changes from the app to other users.

  //==================
  //RANKING RELATED FUNCTIONS
  //==================


  //get top 10 classifiers from FIRESTORE
  //data is filled with backend python script
  async getRankingFromFirestore(): Promise<Classifier[]> {
      try {
        const q = query(
          collection(this.afs, 'stats'),
          orderBy('count', 'desc'),
          limit(10)
        );
        const querySnapshot = await getDocs(q);
        const topClassifiers = await Promise.all(querySnapshot.docs.map(async (document: DocumentData) => {
          const {
            displayName = 'undefined',
            userType = 'User',
            count = 0,
            imageUrl = ''
          } = document.data();

          return {displayName, count, imageUrl, userType};
        }));
        return topClassifiers;
      } catch (error) {
        console.error('Error fetching top 10 classifiers:', error);
        throw (error);
      }
  }
//MONTHLY RANKING FUNCTIONS
//==================
  async getLastMonthlyRankingUpdateTimestamp(month?: string): Promise<number> {
    try {
      const monthKey = month || new Date().toISOString().substring(0, 7);
      const docRef = doc(this.afs, 'monthlyRanking', monthKey);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        console.error('No document for the specified month found in monthlyRanking');
        return 0;
      }

      const timestamp = docSnapshot.data().timestamp as number; // Assuming timestamp is stored as a number
      return timestamp;
    } catch (error) {
      console.error('Error fetching last monthly ranking update timestamp:', error);
      throw error;
    }
  }

  async getMonthlyRankingFromFirestore(month?: string): Promise<{ monthKey: string; classifiers: Classifier[] }> {
    try {
      // Use current month as default if no month is provided
      const monthKey = month || new Date().toISOString().substring(0, 7);
      const docRef = doc(this.afs, 'monthlyRanking', monthKey);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        console.error('No document for the specified month found in monthlyRanking');
        return { monthKey, classifiers: [] };
      }

      const data = docSnapshot.data().ranking;
      const topClassifiers: Classifier[] = [];

      // Assuming rankings are stored with keys 1 to 10 in the monthly document
      for (let i = 1; i <= 10; i++) {
        const ranking = data[String(i)];
        if (ranking) {
          const { displayName = 'undefined', userType = 'User', count = 0, imageUrl = '' } = ranking;
          topClassifiers.push({ displayName, userType, count, imageUrl });
        }
      }

      return { monthKey, classifiers: topClassifiers };
    } catch (error) {
      console.error('Error fetching monthly ranking from Firestore:', error);
      throw error;
    }
  }

  //==================
  //STATISTICS RELATED FUNCTIONS
  //==================

  //count number of users
  async countUsers(): Promise<number> {
    try {
      const q = query(collection(this.afs, 'users'));
      const querySnapshot = await getCountFromServer(q);
      const count = querySnapshot.data().count;
      return count;
    } catch (error) {
      throw error;
    }
  }

  //set timestamp of last classification for uid
  async setLastClassificationTimestampForUid(uid: string): Promise<void> {
    try {
      const userDoc = doc(this.afs, 'users', uid);
      await updateDoc(userDoc, { lastClassificationTimestamp: serverTimestamp() });
    } catch (error) {
      throw error;
    }
  }

  //get timestamp of last classification for uid
  async getLastClassificationTimestampForUid(uid: string): Promise<number> {
    try {
      const userDoc = doc(this.afs, 'users', uid);
      const userDocData = await getDoc(userDoc);
      if (userDocData.exists()) {
        const timestamp = userDocData.data().lastClassificationTimestamp;
        return timestamp;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  //calculate number of days since last timestamp for uid
  async daysSinceLastUserClassification(uid: string): Promise<number> {
    try {
      const timestamp = await this.getLastClassificationTimestampForUid(uid);
      if (timestamp === null) {
        return null;
      }
      const lastClassificationDate = new Date(timestamp);
      const currentDate = new Date();
      const difference = currentDate.getTime() - lastClassificationDate.getTime();
      return Math.floor(difference / (1000 * 60 * 60 * 24));
    } catch (error) {
      throw error;
    }
  }

  //==================
  //USER RELATED FUNCTIONS
  //==================

  async setFirestoreDisplayName(uid: string, displayName: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const userDoc = doc(this.afs, 'users', uid);
        await setDoc(userDoc, { displayName }, { merge: true });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  //get the username for a uid in FIRESTORE database
  async getFirestoreDisplayName(uid: string): Promise<string> {
    try {
        const userDoc = await getDoc(doc(this.afs, 'users', uid));
        const username = userDoc.data()?.displayName;
        return username;
    } catch (error) {
        throw error;
    }
  }

  async getUserType(uid: string, email: string): Promise<UserType> {
    try {
      const userType = await this.getFirestoreUserType(uid);
      // console.log('user type from firestore', userType);
      if (userType !== null && userType !== undefined) {
        return userType;
      } else {
        // console.log('user type not found in firestore');
        return await this.determineUserType(uid, email);
      }
    } catch (error) {
      throw error;
    }
  }

  //function to get the usertype from Firestore based on UID
  async getFirestoreUserType(uid: string): Promise<UserType> {
    try {
      const userDoc = doc(this.afs, 'users', uid);
      const userDocData = await getDoc(userDoc);

      if (userDocData.exists()) {
        const userType = userDocData.data().userType;
        if (userType in UserType) {
          // console.log('found user type in firestore' + userType);
          return UserType[userType as keyof typeof UserType];
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Failed to get user type:', error);
      throw error;
    }
  }

  getUserTypeObservable(uid: string): Observable<UserType> {
    const userDoc = doc(this.afs, 'users', uid);
    return docData(userDoc).pipe(
      map(data => {
        if (data.userType === UserType.Admin) {
          return UserType.Admin;
        }
        if (data.userType === UserType.Demoted) {
          return UserType.Demoted;
        }
        if (data.userType === UserType.Expert) {
          return UserType.Expert;
        } if (data.userType === UserType.SuperUser) {
          return UserType.SuperUser;
        } if (data.userType === UserType.User){
          return UserType.User;
        } else {
          return null;
        }
      })
    );
  }

  async setFirestoreUserType(uid: string, userType: UserType): Promise<void> {
    try {
      const userDoc = doc(this.afs, 'users', uid);
      await setDoc(userDoc, { userType }, { merge: true });
    } catch (error) {
      console.error('Failed to set user type:', error);
      throw error;
    }
  }

  //Function to determine usertype. Is only run when usertype is not found in FIRESTORE, so usually 1 time only
  async determineUserType(uid: string, email: string): Promise<UserType> {
    try {
      // If the email ends with @blackgem.org, the user is an Expert.
      if (email.endsWith('@blackgem.org')) {
        await this.setFirestoreUserType(uid, UserType.Expert);
        // console.log('blackgem user set as expert');
        return UserType.Expert;
      }

      //set ddq users to superuser for testing
      if (email.endsWith('@ddq.nl')) {
        await this.setFirestoreUserType(uid, UserType.Admin);
        // console.log('ddq user set as superuser');
        return UserType.Admin;
      }

      // If none of the above conditions are met, the user is a normal User.
      await this.setFirestoreUserType(uid, UserType.User);
      // console.log('user set as normal user');
      return UserType.User;
    } catch (error) {
      console.error('Failed to determine user type:', error);
      throw error;
    }
  }

  //delete user from FIRESTORE database
  async deleteUser(uid: string): Promise<void> {
    try {
        const userDoc = doc(this.afs, 'users', uid);
        console.log('deleting user from firestore ' + uid);
        await deleteDoc(userDoc);
    } catch (error) {
        throw error;
    }
  }

  async setUserClassificationCount(uid: string, count: number): Promise<void> {
    const userDoc = doc(this.afs, 'users', uid);
    setDoc(userDoc, { classificationCount: count }, { merge: true });
  }

  async getUserClassificationCount(uid: string): Promise<number> {
    // console.log('Getting user classification count for UID:', uid);

    try {
      const userDoc = doc(this.afs, 'users', uid);
      const userDocData = await getDoc(userDoc);

      if (userDocData.exists()) {
        const data = userDocData.data();
        // console.log('Data retrieved from Firestore:', data);

        if (data && typeof data.classificationCount === 'number') {
          // console.log('User classification count found for ' + uid + ':', data.classificationCount);
          return data.classificationCount;
        } else {
          console.warn('Classification count is missing or not a number for UID:', uid);
          return 0;
        }
      } else {
        console.warn('No document found for UID:', uid);
        return 0;
      }
    } catch (error) {
      console.error('Error fetching user classification count for UID:', uid, error);
      return 0;
    }
  }

  async getMonthlyUserClassificationCount(uid: string): Promise<number> {
    const userDoc = doc(this.afs, 'users', uid);
    const userDocData = await getDoc(userDoc);

    let monthlyClassificationCount: number;

    if (userDocData.exists()) {
        monthlyClassificationCount = userDocData.data().monthlyClassificationCount;
    } else {
        monthlyClassificationCount = null;
    }

    return monthlyClassificationCount;
  }

  async incrementUserClassificationCount(uid: string): Promise<void> {
    const userDoc = doc(this.afs, 'users', uid);
    await updateDoc(userDoc, { classificationCount: increment(1) });
  }

  async incrementMonthlyUserClassificationCount(uid: string): Promise<void> {
    const userDoc = doc(this.afs, 'users', uid);
    const userDocData = await getDoc(userDoc);

    let monthlyClassificationCount: number;

    if (userDocData.exists()) {
        monthlyClassificationCount = userDocData.data().monthlyClassificationCount;
    } else {
        monthlyClassificationCount = null;
    }

    const lastClassificationTimestamp = await this.getLastClassificationTimestampForUid(uid);
    const currentTime = Date.now();

    const isSameMonth = lastClassificationTimestamp
        ? new Date(lastClassificationTimestamp).getMonth() === new Date(currentTime).getMonth()
        : true;

    if (!isSameMonth || monthlyClassificationCount === null) {
        // If it's a new month or the monthly count does not exist, get the count from the AnswerService
        const countFromAnswers = await this.answerService.countAnswersForUidThisMonth(uid);
        await updateDoc(userDoc, { monthlyClassificationCount: countFromAnswers + 1 });
    } else {
        // Increment the monthly classification count by 1
        await updateDoc(userDoc, { monthlyClassificationCount: increment(1) });
    }

    //last classification timestamp is updated in the writeClassification function
}

}
