/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  getCountFromServer,
  addDoc,
  serverTimestamp,
  QueryDocumentSnapshot,
  getDocs,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  Timestamp,
  updateDoc,
  QueryConstraint,
} from '@angular/fire/firestore';

import { Answer, AnswerWithImageAndStats } from './dbhc.model';
import { ThumbnailService } from './thumbnail.service';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {
  // Cache the collection reference
  private readonly answersCollection = collection(this.afs, 'answers');

  constructor(private afs: Firestore, private thumbnailService: ThumbnailService) {}
  async getAllAnswerTypesCountsThisMonth() {
    const startOfMonth = new Date();
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    return this.getAllAnswerTypesCounts(startOfMonth);
  }

  // Optimized to use cached collection reference
  async getAnswersForUid(uid: string): Promise<Answer[]> {
    const answersQuery = query(this.answersCollection,
      where('uid', '==', uid),
      // Add index on uid for better performance
      orderBy('timestamp', 'desc')
    );
    const querySnapshot = await getDocs(answersQuery);
    return querySnapshot.docs.map(doc => doc.data() as Answer);
  }

  getAnswersForTransientId(transientId: string) {
    const q = query(this.answersCollection,
      where('transientId', '==', transientId),
      // Add index on transientId for better performance
      orderBy('timestamp', 'desc')
    );
    return getDocs(q);
  }

  // Optimized count queries
  async countAnswersForUid(uid: string) {
    const q = query(this.answersCollection, where('uid', '==', uid));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  async countAnswersForUidThisMonth(uid: string) {
    const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const q = query(this.answersCollection,
      where('uid', '==', uid),
      where('timestamp', '>=', startOfMonth)
    );
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  // Batch processing for better performance
  async getAnswersForUidBatch(
    uid: string,
    limitRecords: number,
    lastDocSnapshot?: QueryDocumentSnapshot
  ): Promise<{
    answers: Answer[];
    lastDocSnapshot: QueryDocumentSnapshot | null;
  }> {
    const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const constraints: QueryConstraint[] = [
      where('uid', '==', uid),
      where('timestamp', '>=', Timestamp.fromDate(startOfMonth)),
      orderBy('timestamp', 'desc'), // Changed to timestamp for better indexing
      limit(limitRecords)
    ];

    if (lastDocSnapshot) {
      constraints.push(startAfter(lastDocSnapshot));
    }

    const q = query(this.answersCollection, ...constraints);
    const querySnapshot = await getDocs(q);

    // Process all documents in parallel
    const answersPromises = querySnapshot.docs.map(async (doc) => {
      const data = doc.data() as AnswerWithImageAndStats;
      data.dateClassified = data.timestamp?.toDate() ?? new Date('2023-01-01');

      // Parallel fetch of additional data
      const [imageUrl, classificationCount, realCount] = await Promise.all([
        this.thumbnailService.getThumbnail(data.transientId).catch(() => ''),
        this.countAnswersByTransientId(data.transientId),
        this.countAnswerTypeForTransientId(data.transientId, 'real')
      ]);

      return {
        ...data,
        thumbnail: imageUrl,
        classificationCount,
        realCount
      };
    });

    const answers = await Promise.all(answersPromises);
    return {
      answers,
      lastDocSnapshot: querySnapshot.docs[querySnapshot.docs.length - 1] || null
    };
  }

  // Optimized write operation
  async addAnswer(transientId: string, uid: string, answer: string) {
    if (answer !== 'real' && answer !== 'bogus' && answer !== 'dunno') {
      throw new Error('Invalid answer type');
    }

    const answerQuery = query(
      this.answersCollection,
      where('transientId', '==', transientId),
      where('uid', '==', uid),
      limit(1) // Limit to 1 since we only need to check existence
    );

    const querySnapshot = await getDocs(answerQuery);
    const answerData = {
      answer,
      timestamp: serverTimestamp(),
      transientId,
      uid
    };

    if (!querySnapshot.empty) {
      await updateDoc(querySnapshot.docs[0].ref, answerData);
    } else {
      await addDoc(this.answersCollection, answerData);
    }
  }

  // Optimized batch counting
  async getAllAnswerTypesCounts(date?: Date) {
    const [realCount, bogusCount, dunnoCount] = await Promise.all([
      this.countAnswersByAnswerType('real', date),
      this.countAnswersByAnswerType('bogus', date),
      this.countAnswersByAnswerType('dunno', date)
    ]);

    return { realCount, bogusCount, dunnoCount };
  }

  async userHasClassifiedTransientId(uid: string, transientId: string): Promise<boolean> {
    const q = query(
      this.answersCollection,
      where('uid', '==', uid),
      where('transientId', '==', transientId),
      limit(1) // Optimize by limiting to 1 document
    );
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  }

  private async countAnswersByTransientId(transientId: string): Promise<number> {
    const q = query(this.answersCollection, where('transientId', '==', transientId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  private async countAnswerTypeForTransientId(transientId: string, answerType: string): Promise<number> {
    const q = query(
      this.answersCollection,
      where('transientId', '==', transientId),
      where('answer', '==', answerType)
    );
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  private async countAnswersByAnswerType(answerType: string, date?: Date): Promise<number> {
    const q = query(this.answersCollection, where('answer', '==', answerType));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }


}
