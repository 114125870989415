import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  signInAnonymously,
  UserCredential,
  sendPasswordResetEmail,
  signInWithCredential,
  sendEmailVerification,
  updateProfile,
  user,
  reauthenticateWithCredential,
  EmailAuthProvider
} from '@angular/fire/auth';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { signInWithPopup, GoogleAuthProvider } from '@angular/fire/auth';
import { AlertController, Platform } from '@ionic/angular';
import { AuthCredential, OAuthProvider, User } from 'firebase/auth';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { NonceService } from './nonce.service';
import { StatsService } from './stats.service';
import { Router } from '@angular/router';
import { AvatarService } from './avatar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user = null;

  constructor(
    private afAuth: Auth,
    private platform: Platform,
    private nonceService: NonceService,
    private statsService: StatsService,
    private router: Router,
    private alertController: AlertController,
    private avatarService: AvatarService,
  ) {
    user(this.afAuth).subscribe((response: User) => {
      this.user = response;
    });
    this.initializeApp();
  }

  getCurrentUser() {
    return new Promise<User>((resolve, reject) => {
      try {
        const unsubscribe = user(this.afAuth).subscribe((userObj) => {
          if (userObj !== null) {
            unsubscribe.unsubscribe(); //unsubscribe to changes, so effectively you get a snapshot of current user (id)
            resolve(userObj); //can also be just the user, but then you always need to extract uid in your code
          } else {
            resolve(null);
          }
        });
      } catch {
        reject(null);
      }
    });
  }

  //Hash function for nonce
  async sha256(message: string) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);
    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // convert bytes to hex string
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  initializeApp() {
    //should only run on web, not needed on native
    if (!this.platform.is('capacitor')) {
      this.platform.ready().then(() => {
        GoogleAuth.initialize({
          clientId:
            '553589883639-2h19rvk5ki52j7h0ptjmmlh5keetm3kj.apps.googleusercontent.com',
          scopes: ['profile', 'email'],
          grantOfflineAccess: false,
        });
      });
    }
  }

  //firebase email/password actions
  async register({ name, email, password }) {
    console.log('received name', name);
    try {
      const loggedInUser = await createUserWithEmailAndPassword(
        this.afAuth,
        email,
        password
      );
      await this.sendVerificationMail();
      await this.updateUserName(name);
      return loggedInUser;
    } catch (e) {
      return null;
    }
  }

  async login({ email, password }) {
    try {
      const loggedInUser = await signInWithEmailAndPassword(
        this.afAuth,
        email,
        password
      );
      this.updateFirestoreDisplayName(
        loggedInUser.user.uid,
        loggedInUser.user.displayName
      );
      return loggedInUser;
    } catch (e) {
      return null;
    }
  }

  async updateUserName(name: string) {
    const currentUser = this.afAuth.currentUser;
    const uid = currentUser.uid;
    //update username in auth profile
    await updateProfile(currentUser, { displayName: name });
    //update username in firestore for ranking name
    this.updateFirestoreDisplayName(uid, name);
  }

  async updateFirestoreDisplayName(uid: string, name: string) {
    if (name !== null) {
      this.statsService.setFirestoreDisplayName(uid, name);
    }
  }

  async sendPasswordResetEmail(email: string) {
    console.log('resetting password for email', email);
    try {
      sendPasswordResetEmail(this.afAuth, email).then();
    } catch (e) {
      return null;
    }
  }

  async sendVerificationMail() {
    console.log('sending verification email');
    await sendEmailVerification(this.afAuth.currentUser);
  }

  async anonymousLogin() {
    const anonymousUser = await signInAnonymously(this.afAuth);
    console.log('anonymous login', anonymousUser);
    return anonymousUser;
  }

  //Google Authentication
  async loginWithGoogle() {
    let loggedInUser = null;
    const googleAuthProvider = new GoogleAuthProvider();
    googleAuthProvider.addScope('email');

    if (this.platform.is('android') || this.platform.is('ios')) {
      // console.log('Native login');
      const googleUser = await GoogleAuth.signIn();
      const googleOAuthProvider = new OAuthProvider('google.com');
      //allow user to select his google account (if there are more)
      googleOAuthProvider.setCustomParameters({
        prompt: 'select_account',
      });
      const credential = googleOAuthProvider.credential({
        idToken: googleUser.authentication.idToken,
      });
      await signInWithCredential(this.afAuth, credential).then(
        (signedInUser) => {
          loggedInUser = signedInUser;
          this.updateFirestoreDisplayName(signedInUser.user.uid,signedInUser.user.displayName);
        }
      );
    }
    else {
      console.log('Google Desktop login');
      loggedInUser = await signInWithPopup(
        this.afAuth,
        new GoogleAuthProvider()
      );
      this.updateFirestoreDisplayName(loggedInUser.user.uid,loggedInUser.user.displayName);
    }
    return loggedInUser;
  }

  //Apple Authentication
  loginWithApple() {
    let loggedInUser = null;
    if (!this.platform.is('capacitor')) {
      loggedInUser = this.loginWithAppleWeb();
    } else {
      loggedInUser = this.loginWithAppleNative();
    }
    return loggedInUser;
  }

  //also needs part for native, signinwithpopup doesn't work on native
  loginWithAppleWeb() {
    console.log('Apple Web login');
    const provider = new OAuthProvider('apple.com');
    const loggedInUser = signInWithPopup(this.afAuth, provider).then(
      async (signedInUser: UserCredential) => {
        console.log('logged in with apple Web', signedInUser);
        if (signedInUser.user.displayName !== null) {
          //console.log('user display name is not null');
          //console.log('updating display name', signedInUser.user.displayName);
          const displayName = await this.fixAppleDisplayName(signedInUser.user.displayName);

          await this.updateUserName(displayName);
        }
      }
    );
    return loggedInUser;
  }

  // Apple native log in. First log in with Apple/ios (works)
  // Then grab the token and log in to firebase.
  async loginWithAppleNative() {
    console.log('Apple Native login');
    let loggedInUser = null;
    const nonce = this.nonceService.generateNonce();
    const hashedNonceHex = await this.sha256(nonce); // see next function

    const options: SignInWithAppleOptions = {
      clientId: 'nl.ddq.blackholefinder',
      redirectURI: 'https://blackholefinder.firebaseapp.com/__/auth/handler',
      scopes: 'email, name',
      state: '123456',
      nonce: hashedNonceHex,
    };

    const appleUser: SignInWithAppleResponse = await SignInWithApple.authorize(
      options
    );
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: appleUser.response.identityToken,
      rawNonce: nonce,
    });

    await signInWithCredential(this.afAuth, credential).then(async (signedInUser) => {
      loggedInUser = signedInUser;
      if (signedInUser.user.displayName !== null) {
        //console.log('updating display name', signedInUser.user.displayName);
        const displayName = await this.fixAppleDisplayName(signedInUser.user.displayName);
        await this.updateUserName(displayName);
      }
      //console.log(signedInUser);
    });
    return loggedInUser;
  }

  logout() {
    return signOut(this.afAuth);
  }

  fixAppleDisplayName(inputName: string): Promise<string> {
    return new Promise<string>((resolve) => {
      let displayName = inputName.replace(/\+/g, ' ');
      displayName = displayName.trim();
      if (displayName === '') {
        displayName = 'No name provided'; // Set manually if empty
      }
      // console.log('display name fixed:', displayName);
      resolve(displayName);
    });
  }

  async deleteAccount(): Promise<void> {
    let credential = null;
    let deleteUser = this.afAuth.currentUser;
    if (deleteUser) {
      // console.log('user found');
      //console.log(deleteUser);
    //check if user is anonymous
    if(deleteUser.isAnonymous){
      console.log('user is anonymous');
      await this.alertController.create({
        header: 'Delete account? This cannot be undone',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Cancelled Delete');
            }
          },
          {
            text: 'DELETE',
            handler: () => {
              this.reauthenticateAndDeleteAccount(deleteUser, null);}
            },
        ],
      }).then(async (alert) => {
      await alert.present();
      });
      return;
    } else {
  //user registered with password ID provider
      console.log('user is not anonymous');
      switch (deleteUser.providerData[0].providerId) {
        case 'password':
          console.log('user with password');
          await this.alertController.create({
            header: 'Delete account? This cannot be undone',
            message: 'Enter the password of the logged in user to confirm',
            inputs: [
              {
                name: 'password',
                placeholder: 'Password',
                type: 'password'
              }
            ],
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Cancelled Delete');
                }
              },
              {
                text: 'DELETE',
                handler: (alertData) => {
                  credential = EmailAuthProvider.credential(deleteUser.email,alertData.password);
                  //console.log(credential);
                  this.reauthenticateAndDeleteAccount(deleteUser, credential);
                },
              },
            ],
          }).then(async (alert) => {
          await alert.present();
          });
          break;
  //user logged in with Google
        case 'google.com':
          console.log('user with google');
          const googleOAuthProvider = new OAuthProvider('google.com');
          const googleUser = await GoogleAuth.signIn();
          credential = googleOAuthProvider.credential({
            idToken: googleUser.authentication.idToken,
          });
          console.log('deleting google user');
          this.reauthenticateAndDeleteAccount(deleteUser, credential);
          break;

  //user logged in with Apple
        case 'apple.com':
          console.log('user with apple');
          const appleOAuthprovider = new OAuthProvider('apple.com');

          if (!this.platform.is('capacitor')) {
          signInWithPopup(this.afAuth, appleOAuthprovider)
          .then((result) => {
            // The signed-in user info.
            deleteUser = result.user;

            // Apple credential
            credential = OAuthProvider.credentialFromResult(result);
            console.log('deleting apple user');
            this.reauthenticateAndDeleteAccount(deleteUser, credential);
          });
        } else {
            console.log('apple user came from native');
            const nonce = this.nonceService.generateNonce();
            const hashedNonceHex = await this.sha256(nonce);

            const options: SignInWithAppleOptions = {
              clientId: 'nl.ddq.blackholefinder',
              redirectURI: 'https://blackholefinder.firebaseapp.com/__/auth/handler',
              scopes: 'email, name',
              state: '123456',
              nonce: hashedNonceHex,
            };
            const appleUser: SignInWithAppleResponse = await SignInWithApple.authorize(options);
            credential = appleOAuthprovider.credential({
            idToken: appleUser.response.identityToken,
            rawNonce: nonce,
          });
          this.reauthenticateAndDeleteAccount(deleteUser, credential);
          }
          break;
        default:
          throw new Error('Unknown provider');
        } //end switch
      } //end not anonymous
    } //end user found
  } //end deleteAccount

  //should delete user in firebase and in firestore
  async reauthenticateAndDeleteAccount(deleteUser: User, credential: AuthCredential) {
   if (deleteUser.isAnonymous){
    this.router.navigateByUrl('/home', { replaceUrl: true });
    return await deleteUser.delete();
   } else {
    this.router.navigateByUrl('/home', { replaceUrl: true });
    //delete the user from firestore
    await this.statsService.deleteUser(deleteUser.uid);
    //reauthenticate user with credentials and delete user
    return reauthenticateWithCredential(deleteUser, credential).then(() =>
      deleteUser.delete().then(() => {
      this.avatarService.removeImage();
   })
   );
   }
  }

}
