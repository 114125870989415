import { Injectable } from '@angular/core';
import { Auth, updateProfile } from '@angular/fire/auth';
import { deleteField, doc, Firestore, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';
import { ref, uploadString, getDownloadURL, Storage, deleteObject } from '@angular/fire/storage';
import { Photo } from '@capacitor/camera';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  constructor(private afAuth: Auth, private firestore: Firestore, private storage: Storage) { }

  getAvatarForUser(uid: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let imageUrl = null;
      try {
        const userDoc = await getDoc(doc(this.firestore, 'users', uid));
        if (userDoc.exists() === false) {
          console.log('Avatar Service: no user found');
          resolve('');
        } else {
          imageUrl = userDoc.data().imageUrl;
          if (imageUrl === undefined || imageUrl === null) {
            imageUrl = '';
          }
        }
        resolve(imageUrl);
      } catch (error) {
        reject(error);
      }
    });
  }

  async uploadImage(cameraFile: Photo) {
    const user = this.afAuth.currentUser;
    const path = `uploads/${user.uid}/profile.png`;
    const storageRef = ref(this.storage, path);

    try {
      await uploadString(storageRef, cameraFile.base64String, 'base64');
      const imageUrl = await getDownloadURL(storageRef);
      this.updateFirestorePhotoURL(imageUrl);
      console.log(imageUrl);
      //update photo url in firebase user profile
      await this.updateFirebasePhotoURL(imageUrl);
      return imageUrl;
    } catch (e) {
      return null;
    }
  }

  async updateFirebasePhotoURL(newPhotoURL: string) {
    await updateProfile(this.afAuth.currentUser, { photoURL: newPhotoURL });
  }

  async updateFirestorePhotoURL(newPhotoURL: string) {
    // console.log('avatar Service: updating firestore photo url');
    const user = this.afAuth.currentUser;
    const userDocRef = doc(this.firestore, `users/${user.uid}`);
    await setDoc(userDocRef, {imageUrl: newPhotoURL}, {merge: true});
  }

  removeImage(){
    const user = this.afAuth.currentUser;
    //remove profile url from Firebase Authentication Profile
    this.updateFirebasePhotoURL('');
    //delete the reference to the image in Firestore Database
    const userDocRef = doc(this.firestore, `users/${user.uid}`);
    if (userDocRef){
    updateDoc(userDocRef, { imageUrl: deleteField() });
    }
    //delete the actual image from Firebase Storage
    const path = `uploads/${user.uid}/profile.png`;
    const storageRef = ref(this.storage, path);
    deleteObject(storageRef).then(
      () => {
        console.log('Avatar Service: image deleted from storage');
      }
    ).catch(
      (error) => {
        console.error('Avatar Service: No Image or error deleting image from storage');
      }
    );
  }


}
