import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from  '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ThumbnailService {

  constructor(private afs: Firestore) { }

async getThumbnail(transientId: string){
  const transientDoc = doc(this.afs, 'transients', transientId);
  const snapshot  = await getDoc(transientDoc);
  if (snapshot.exists()) {
    const thumbnailRef = snapshot.get('thumbnail_red');
    return thumbnailRef;
  } else {
    throw new Error(`Document with ID ${transientId} does not exist.`);
  }
}
}
